import { useGetMpio } from "../hooks/useGetMpio";

const Casillas = ({ casillas, muniId }) => {
  
  muniId = useGetMpio()[0];

  const camposPartidos     = muniId === 21 ? casillas.campos_partidos_21     : casillas.campos_partidos_40;
  const camposCandidaturas = muniId === 21 ? casillas.campos_candidaturas_21 : casillas.campos_candidaturas_40;
  const actas = casillas.actas;

  return (
    <div className="col-md-12 py-3 seccion-resumen">
      <div className="card">
        <div className="card-body">
          <h4 className="subtitulo-seccion pleca-izquierda my-3">
            <strong>Votos en Actas Contabilizadas</strong>
          </h4>
          <div className="row">
            <div className="col-md-12">
              <div className="col-md-12 px-0 mt-4">
                <div className="acta-digitalizada container-fluid">
                  <div className="row">
                    <div className="col-md-7 pl-0">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-md-5 dis-flex border-rightB padding-r-0">
                            <p className="font-medium text-left padding-r-0 padding-b-15">
                              Imagen procedente de:
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4 dis-flex border-rightB padding-r-0">
                            <div className="grupo gris dis-flex">
                              <i className="fas fa-file txt-gray"></i>
                              <i className="fa fa-hourglass mr-2 txt-gray"></i>
                              Acta en proceso
                              <div className="vline"></div>
                            </div>
                          </div>
                          <div className="col-md-2 dis-flex border-rightB padding-r-0 padding-l-0">
                            <div
                              tooltip="Acta PREP digitalizada por medio de escáner."
                              placement="top"
                              max-width="500"
                              className="grupo dis-flex cursor-hand"
                            >
                              <i className="far fa-file txt-gray"></i>
                              <i
                                aria-hidden="true"
                                className="fas fa-print mr-2 txt-gray"
                              ></i>
                              Escáner
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="table-responsive tabla-seccion max-500">
                    <table className="table table-striped contenido table-bordered">
                      <thead className="titulos">
                        <tr>
                          <th className="table-header-background">
                            <strong>Casilla</strong>
                          </th>
                          <th className=" table-header-background">
                            <strong>Acta digitalizada</strong>
                          </th>
                          {
                            Object.values(camposPartidos).map((campo_partido,index) => (
                              <th key={index}>
                                <img 
                                  className="img-partido"
                                  src={require(`../assets/img/${campo_partido.campo.campo}.png`)} 
                                  alt={campo_partido.campo.campo} 
                                />
                              </th>
                            ))
                          }
                          {
                            Object.values(camposCandidaturas).map((campo_cc,index) => (
                              <th key={index}>
                                <img 
                                  className="img-partido"
                                  src={require(`../assets/img/${campo_cc.campo.campo}.png`)} 
                                  alt={campo_cc.campo.campo} 
                                />
                              </th>
                            ))
                          }
                          <th>
                            <strong> Candidaturas <br/>No Registradas </strong>
                          </th>
                          <th>
                            <strong> Votos Nulos </strong>
                          </th>
                          <th>
                            <strong> Total </strong>
                          </th>
                          <th>
                            <strong> Observaciones en Acta </strong>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          actas.map((acta,index) => (
                            (
                              acta.municipio_id === muniId
                              ? (
                                <tr key={acta.id}>
                                  <td>
                                    {
                                      acta.casilla_id !== null
                                       ? acta.casilla.casilla
                                       : '-'
                                    }
                                  </td>
                                  <td>
                                    {
                                      acta.imagen.disponible && acta.verificada
                                      ? (
                                        <a href={`${process.env.PUBLIC_URL}/actas/images/${acta.imagen.imagen}`} target="_blank" rel="noreferrer">
                                          <i class="far fa-file txt-gray"></i>
                                          <i class="fas fa-print txt-gray"></i>
                                        </a>
                                      )
                                      : ''
                                    }
                                  </td>
                                  {
                                    Object.values(camposPartidos).map((campo_partido,index) => (
                                      <td key={index}>
                                        {
                                          acta.verificada
                                          ? (`${acta.voto_ayuntamiento[campo_partido.campo_id+'_']}`)
                                          : ''
                                        }
                                      </td>
                                    ))
                                  }
                                  {
                                    Object.values(camposCandidaturas).map((campo_cc,index) => (
                                      <td key={index}>
                                        {
                                          acta.verificada
                                          ? (`${acta.voto_ayuntamiento[campo_cc.campo_id+'_']}`)
                                          : ''
                                        }
                                      </td>
                                    ))
                                  }
                                  <td>
                                    {
                                      acta.verificada ? acta.voto_ayuntamiento['9_'] : ''
                                    }
                                  </td>
                                  <td>
                                    {
                                      acta.verificada ? acta.voto_ayuntamiento['10_'] : ''
                                    }
                                  </td>
                                  <td>
                                    {
                                      acta.verificada ? acta.voto_ayuntamiento['11_'] : ''
                                    }
                                  </td>
                                  <td>
                                    {
                                      acta.verificada ? acta.observaciones : ''
                                    }
                                  </td>
                                </tr>
                              )
                              : ''
                            )
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Casillas;
