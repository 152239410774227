const Footer = () => {
  return (
    <div className="col-md-12 mb-3">
      <div className="row justify-content-center">
        <div className="col-md-10 px12 text-center">
          <p className="m-0">
              Los resultados presentados tienen un carácter informativo y no son definitivos, por tanto, no tienen efectos
              jurídicos.
          </p>
          <p className="m-0">
              Con base en la Ley Federal del Derecho de Autor queda prohibida cualquier modificación al diseño de este
              sitio.<br/> La modificación y/o destrucción de la información y/o contenido total o parcial de este sitio, es
              delito federal de acuerdo al Código Penal Federal.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
