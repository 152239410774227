// import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { Link, useLocation } from "react-router-dom";

const Menu = () => {
  const location = useLocation();

  return (
    <div className="w-100 bg-white shadow">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <Nav variant="pills">
              <Nav.Item>
                <Link 
                  className={location.pathname === '/ayuntamientos/021-chichimila' ? 'nav-link active' : 'nav-link'} 
                  to="/ayuntamientos/021-chichimila">
                  Chichimilá
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link
                  className={location.pathname === '/ayuntamientos/040-izamal' ? 'nav-link active' : 'nav-link'} 
                  to="/ayuntamientos/040-izamal">
                  Izamal
                </Link>
              </Nav.Item>
            </Nav>

            {/* <ul className="nav nav-pills p-3">
              <li className="nav-item">
                <Link 
                  to={process.env.PUBLIC_URL+'/ayuntamientos/021-chichimila'} 
                  activeClassName="active"
                >
                  
                  Chichimilá
                </Link>
              </li>
              <li className="nav-item">
                <Link 
                  to={process.env.PUBLIC_URL+'/ayuntamientos/040-izamal'} 
                  activeClassName="active"
                >
                  
                  Izamal
                </Link>
              </li>
            </ul> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
