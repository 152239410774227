import { useFetch } from "../hooks/useFetch";

import Header from '../components/Header'
import Avance from '../components/Avance'
import Menu from '../components/Menu';
import VotosCandidatura from '../components/VotosCandidatura';
import Participantes from '../components/Participantes';
import Casillas from '../components/Casillas';
import DistribucionCandidatura from '../components/DistribucionCandidatura';
import DistribucionPartidos from "../components/DistribucionPartidos";
import Estadistica from "../components/Estadistica";
import Footer from "../components/Footer";


const mpioId = 21

const Chichimila = () => {
  
  const { data, isLoading } = useFetch();
  
  return (
    <>
      <Header/>
      <div className='container-fluid'>
        <div className="row">
          { isLoading ? <p className="fs-4 mt-5">Espere....</p> :
            <>
              <Avance avance={data}/>
              <Menu/>
              <VotosCandidatura votosCandidatura={data}/>
              <Participantes participantes={data} muniId={mpioId}/>
              <Casillas casillas={data} muniId={mpioId} />
              <DistribucionCandidatura distribucion={data} muniId={mpioId} />
              <DistribucionPartidos distribucionPartidos={data} muniId={mpioId} />
              <Estadistica estadistica={data} muniId={mpioId} />
              <Footer />
            </>
          }
        </div>
      </div>
    </>
  )
}

export default Chichimila