import { useGetMpio } from "../hooks/useGetMpio";
import avatar from "../assets/img/avatar-persona.svg";
import { NumericFormat } from "react-number-format";

const DistribucionCandidatura = ({ distribucion, muniId }) => {
  
  muniId = useGetMpio()[0];

  const totalDet21 = distribucion.totales.totales_detalle_21;
  const totalDet40 = distribucion.totales.totales_detalle_40;

  const totales = muniId === 21 ? totalDet21 : totalDet40;
  
  return (
    <div className="col-md-12 py-3 distribucion-votos ">
      <div className="card">
        <div className="card-body">
          <h4 class="subtitulo-seccion pleca-izquierda my-3">
            <strong>
              Distribución de votos por Candidatura a nivel Municipio
            </strong>
          </h4>
          <div className="row">
            <p class="em14">
              La tabla muestra el desglose de votos que cada Candidatura
              presenta en el Municipio, indicando los votos recibidos de manera
              individual y, en su caso, los votos recibidos vía Candidatura
              Común.
            </p>
            <p class="em14 underline">
              <br /> Conoce cómo se suman los votos para Candidaturas de
              Candidatura Común de acuerdo con la legislación vigente.
            </p>
          </div>
          <div className="tabla pt-3">
            <div className="titulos row borde-abajo">
              <div className="col-4 col-lg-3 borde-derecha">
                <strong> Candidaturas </strong>
              </div>
              <div className="col-5 col-lg-5 borde-derecha text-center">
                <strong> Distribución de votos por Candidatura </strong>
              </div>
              <div className="col-3 col-lg-2 text-center">
                <strong> Total de votos por Candidatura </strong>
              </div>
            </div>
            {totales.orden_grafica.map((orden, index) => (
              <div key={index} class="row datos">
                <div className="col-4 col-lg-3 borde-derecha py-3">
                  <p>
                    <strong>{orden.candidato}</strong>
                  </p>
                  <img src={avatar} alt="avatar" />
                </div>
                <div className="col-5 col-lg-5 borde-derecha text-right padding-t-15">
                  {Object.values(totales.orden_grupo[index]).map(
                    (grupo, idxgrupo) =>
                      grupo.campo_id !== "total" ? (
                        <>
                          <img
                            className="img-partido"
                            src={require(`../assets/img/${grupo.partido}.png`)}
                            alt={grupo.partido}
                          />
                          <strong>
                            <NumericFormat
                              value={grupo.voto}
                              thousandSeparator=","
                              displayType="text"
                            />
                          </strong>
                          &nbsp;

                          {
                            orden.cc && idxgrupo < orden.partido.split('-').length ? (
                              <i className="fas fa-plus-circle"></i>
                            ) : (
                              ""
                            )
                          }
                        </>
                      ) : (
                        ""
                      )
                  )}
                </div>
                <div className="col-3 col-lg-2 dis-flex flex-col text-center">
                  <div className="conteo dis-flex justify-s w-100">
                    <div className="igual dis-flex mx-sm-3">
                        <i aria-hidden="true" className="fas fa-equals"></i>
                    </div>
                    <p className="total">
                      { orden.voto }
                    </p>
                  </div>
                  <p class="px14"> Total de votos por candidatura </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DistribucionCandidatura;
