import { NumericFormat } from "react-number-format";
import { useGetMpio } from "../hooks/useGetMpio";

const Estadistica = ({ estadistica, muniId }) => {
  muniId = useGetMpio()[0];
  const municipio = useGetMpio()[1];

  const totalEst21 = estadistica.totales.estadistico_actas_21;
  const totalEst40 = estadistica.totales.estadistico_actas_40;

  const porActasCap21 = estadistica.totales.porcentaje_actas_capturadas_21;
  const porActasCap40 = estadistica.totales.porcentaje_actas_capturadas_40;

  const porActasCon21 = estadistica.totales.porcentaje_actas_contabilizadas_21;
  const porActasCon40 = estadistica.totales.porcentaje_actas_contabilizadas_40;
  
  const lnCont21      = estadistica.totales.ln_contabilizadas_21;
  const lnCont40      = estadistica.totales.ln_contabilizadas_40;

  const participa21   = estadistica.totales.participacion_21;
  const participa40   = estadistica.totales.participacion_40;

  const totales     = muniId === 21 ? totalEst21    : totalEst40;
  const porActasCap = muniId === 21 ? porActasCap21 : porActasCap40;
  const porActasCon = muniId === 21 ? porActasCon21 : porActasCon40;
  const lnCont      = muniId === 21 ? lnCont21      : lnCont40;
  const participa   = muniId === 21 ? participa21   : participa40;

  // console.log(totales);

  return (
    <div className="col-md-12 py-3 estadisticaNacional">
      <div className="card">
        <div className="card-body">
          <h4 className="subtitulo-seccion pleca-izquierda my-3">
            <strong>Estadística Municipio { municipio } </strong>
          </h4>
          <div className="row d-flex justify-content-center lign-items-start">
            <div className="col-md-4">
              <p className="titulo pb-3" style={{
                textDecoration: 'underline'
              }}>
                <strong>Actas del Municipio</strong>
              </p>
              <div className="row dis-flex space-a text-center">
                <div className="col-md-4 dis-flex flex-col">
                  <div className="grafica">
                    <div
                      className="progreso bgGrafica1" style={{
                        height: '100%'
                      }}
                    ></div>
                  </div>
                  <p className="px16 pt-1">
                    <strong>{ totales.esperadas }</strong>
                  </p>
                  <p className="px14">
                    100.0000% <br />
                    <span className="px12">Esperadas</span>
                  </p>
                </div>
                <div className="col-md-4 dis-flex flex-col">
                  <div className="grafica">
                    <div className="progreso bgGrafica2" style={{
                      height: `${porActasCap}%`
                    }}></div>
                  </div>
                  <p className="px16 pt-1">
                    <strong>{ totales.capturadas }</strong>
                  </p>
                  <p className="px14">
                    { porActasCap }% <br/>
                    <span className="px12">Capturadas</span>
                  </p>
                </div>
                <div className="col-md-4 dis-flex flex-col">
                  <div className="grafica">
                    <div className="progreso bgGrafica3" style={{
                      height: `${porActasCon}%`	
                    }}></div>
                  </div>
                  <p className="px16 pt-1">
                    <strong>{ totales.contabilizadas }</strong>
                  </p>
                  <p className="px14">
                    { porActasCon }% <br/>
                    <span className="px12">Contabilizadas</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <p className="titulo pb-3">
                <strong>Lista Nominal </strong>
              </p>
              <div>
                <p className="total-numero" style={{
                  textAlign: 'center'
                }}>
                  <strong>
                    <NumericFormat
                      value={lnCont}
                      thousandSeparator=","
                      displayType="text"
                    />
                  </strong>
                </p>
                <p className="px12" style={{
                  textAlign: 'center'
                }}>
                  Lista Nominal de Actas Contabilizadas
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <p className="titulo p-3">
                <strong>
                  Participación ciudadana con base en la Lista Nominal de las
                  Actas PREP Contabilizadas
                </strong>
              </p>
              <div>
                <p
                  className="px38 font-medium text-center"
                  style={{
                    color: '#D1308A'
                  }}
                >
                  { participa }%
                  <div
                    className="texto text-center"
                    style={{
                      color: '#000000'
                    }}
                  ></div>
                  <div className="texto text-center" style={{
                    fontSize: '14px'
                  }}>
                    (Total de votos de las Actas Contabilizadas/ Lista Nominal)
                    * 100 = % de Participación Ciudadana.
                  </div>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Estadistica;
