import { Navigate, Route, Routes } from "react-router-dom";
import Inicio from './pages/Inicio'
import Chichimila from './pages/Chichimila'
import Izamal from './pages/Izamal'

const App = () => {
  
  // const location = useLocation();
    return (
    <>
      <Routes>
        <Route path={`${process.env.PUBLIC_URL}/`} element={<Inicio />} />
        <Route path={`${process.env.PUBLIC_URL}/ayuntamientos/021-chichimila`} element={<Chichimila />} />
        <Route path={`${process.env.PUBLIC_URL}/ayuntamientos/040-izamal`} element={<Izamal />} />
        <Route path={`${process.env.PUBLIC_URL}/*`} element={<Navigate to="/ayuntamientos/021-chichimila" />} />
      </Routes>
      
    </>
  );
}

export default App;
