import { useState } from "react";
import React from "react";
import Modal from "react-bootstrap/Modal";

const Avance = ({ avance }) => {
  const { fecha_corte, totales } = avance;
  const { estadistico_actas } = totales;

  const [show, setShow] = useState(false);
  const handleClose     = () => setShow(false);
  const handleShow      = () => setShow(true);

  const refreshPage = () => {
    window.location.reload();
  }

  return (
    <>
      <div className="col-md-12 mb-3 mt-150">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <h3 className="txPrimario fw-bold px20">Avance</h3>
              <div className="actualizar dis-flex flex-col px-4 py-1 px14 cursorPointer mb-2">
                <i aria-hidden="true" className="fas fa-redo"></i>
                <span className="updateTxt" onClick={ refreshPage } >Actualizar</span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="card cards-avance p-1 brdr-card-avance">
                  <div className="titulo">
                    <strong>Actas capturadas</strong>
                  </div>
                  <div className="contenido capturadas mt-1">
                    <p>
                      <strong>{estadistico_actas.capturadas}</strong>&nbsp; de
                      &nbsp;{estadistico_actas.esperadas}
                    </p>
                    <p className="mt-0">
                      {totales.porcentaje_actas_capturadas}%
                    </p>
                    <div className="w-100 barra-container borde-barra">
                      <div
                        className="barra-avance"
                        style={{
                          width: `${totales.porcentaje_actas_capturadas}%`
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card cards-avance p-1 brdr-card-avance">
                  <div className="titulo">
                    <strong>
                      <i
                        onClick={handleShow}
                        className="fas fa-question-circle cursor-hand"
                      ></i>
                      Participación ciudadana
                    </strong>
                  </div>
                  <div className="contenido dis-flex flex-col pt-3">
                    <p className="participacion mt-3">
                      <strong>{totales.participacion}%</strong>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card cards-avance p-1 brdr-card-avance">
                  <div className="titulo">
                    <strong> Último corte </strong>
                  </div>
                  <div className="contenido dis-flex flex-col align-e pt-3 mb-1">
                    <div className="dis-flex w-100">
                      <p className="fecha mr-1">
                        {fecha_corte.dia} &nbsp;
                        {fecha_corte.mes} &nbsp;
                        {fecha_corte.anio}
                      </p>
                      <div className="dis-flex flex-col borde-tiempo">
                        <p className="tiempo-centro mb-0">Hora local</p>
                        <p className="utc">
                          <strong>{fecha_corte.hora}</strong> h UTC-6
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal 
        show={show} 
        onHide={handleClose}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Conoce cómo calcular el porcentaje de Participación ciudadana
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            La Participación ciudadana es el porcentaje de ciudadanos que
            votaron, con base en la Lista Nominal de las Actas Contabilizadas.
          </p>
          <p>
            Por ejemplo, si en una casilla votan 500 electores y el número de
            personas inscritas en la Lista Nominal para esa casilla es de 750
            ciudadanos, entonces:
          </p>
          <p>
            Multiplicamos 500 por 100 y el resultado lo dividimos entre 750.
          </p>
          <p>
            La votación emitida en las Casillas Especiales se tomará en cuenta
            para el cálculo de la Participación ciudadana, hasta el último corte
            de información que se publique en el PREP.
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Avance;
