import { useEffect, useState } from "react";
import http from '../http';

export const useFetch = () => {
  
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    
    const getData = async () => {
      setIsLoading(true);
      const date = Date('YmdHis');
      await http.get('/actas/datos.json?'+date)
        .then((resp) => {
          const data = resp.data;
          setIsLoading(false);
          setData(data);
        }
      )
    }
    getData()
  },[]);

  return {
    data,
    isLoading
  }

}
