// import { useState } from "react";
import { useLocation } from "react-router-dom";

export const useGetMpio = () => {

  const location = useLocation();
  let mpioId    = 21;
  let municipio = 'Chichimila';

  switch(location.pathname)
  {
    case '/ayuntamientos/021-chichimila':
      mpioId    = 21;
      municipio = 'Chichimila';
      break;
    case '/ayuntamientos/040-izamal':
      mpioId    = 40;
      municipio = 'Izamal';
      break;
    default:
      break;
  }

  return [
    mpioId,
    municipio
  ]
}