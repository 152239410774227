import { NumericFormat } from "react-number-format";
import { useGetMpio } from "../hooks/useGetMpio";

const DistribucionPartidos = ({ distribucionPartidos, muniId }) => {
  
  muniId = useGetMpio()[0];

  const camposPartidos =
    muniId === 21
      ? distribucionPartidos.campos_partidos_21
      : distribucionPartidos.campos_partidos_40;

  const totalDet21 = distribucionPartidos.totales.totales_detalle_21;
  const totalDet40 = distribucionPartidos.totales.totales_detalle_40;

  const totales = muniId === 21 ? totalDet21 : totalDet40;

  return (
    <div className="col-md-12 py-3 distribucion-votos">
      <div className="card">
        <div className="card-body">
          <h4 className="subtitulo-seccion pleca-izquierda my-3">
            <strong>
              Distribución de votos por Partidos Políticos a nivel Municipio
            </strong>
          </h4>
          <div className="row">
            <p className="em14">
              La tabla muestra el desglose de votos que cada Partido Político
              presenta en el Municipio, indicando los votos recibidos de manera
              individual y, en su caso, los votos recibidos vía Candidatura
              Común.
            </p>
            <p className="em14 underline">
              <br /> Conoce cómo se distribuyen los votos para Candidatura de
              Candidatura Común de acuerdo con la legislación vigente.
            </p>
          </div>
          <div className="tabla pt-3">
            <div className="titulos row text-center">
              <div className="col-3">
                <p>
                  <strong>Partidos Políticos</strong>
                </p>
              </div>
              <div className="col-3">
                <strong>
                  Votos para <br />
                  Partidos Políticos
                </strong>
              </div>
              <div className="col-3">
                <strong>
                  Votos obtenidos vía <br />
                  Candidatura Común
                </strong>
              </div>
              <div className="col-3">
                <strong>Total de votos</strong>
              </div>
            </div>
            {Object.values(camposPartidos).map((partido, index) => (
              <div key={index} className="row datos">
                <div className="col-3">
                  <div className="distritos py-4 w-100 dis-flex">
                    <img
                      className="img-partido"
                      src={require(`../assets/img/${partido.campo.campo}.png`)}
                      alt={partido.campo.campo}
                    />
                  </div>
                </div>
                {
                  totales.orden_grupo.map((grupos, keyGrupos) => (
                    Object.values(grupos).map((grupo, keyGrupo) => (
                      grupo.campo_id === partido.campo_id
                        ? (
                            <>
                              <div className="col-3 dis-flex">
                                <p>
                                  <NumericFormat
                                    value={grupo.voto}
                                    thousandSeparator=","
                                    displayType="text"
                                  />
                                </p>
                              </div>
                              <div className="col-1 dis-flex flex-col text-center">
                                <div className="conteo dis-flex justify-s w-100">
                                  <div className="igual dis-flex mx-sm-3">
                                      <i aria-hidden="true" className="fas fa-plus"></i>
                                  </div> 
                                </div>
                              </div>
                              <div className="col-2 dis-flex">
                                <p className="dis-flex mx-3 my-2">
                                  {
                                    muniId === 21
                                      ? (
                                          partido.grupo_suma >= 2 && partido.grupo_suma <= 3
                                          ? 'Sin Candidatura Común'
                                          : grupo.voto_obtenido
                                        )
                                      : partido.grupo_suma === 2 || partido.grupo_suma === 4
                                        ? 'Sin Candidatura Común'
                                        : grupo.voto_obtenido
                                  }
                                </p>
                              </div>
                              <div className="col-1 dis-flex flex-col text-center">
                                <div className="conteo dis-flex justify-s w-100">
                                  <div className="igual dis-flex mx-sm-3">
                                      <i aria-hidden="true" className="fas fa-equals"></i>
                                  </div>
                                </div>
                              </div>
                              <div className="col-2 dis-flex">
                                <p className="total dis-flex mx-3 my-2">
                                  <NumericFormat
                                    value={grupo.voto_rp}
                                    thousandSeparator=","
                                    displayType="text"
                                  />
                                </p>
                              </div>
                            </>
                          )
                        : ''
                    ))
                  ))
                }
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DistribucionPartidos;
