// import { useLocation } from "react-router-dom";
import { useGetMpio } from "../hooks/useGetMpio";

const VotosCandidatura = ({ votosCandidatura }) => {
  
  const municipio  =  useGetMpio()[1];
  const { fecha_corte } = votosCandidatura;
  const url = process.env.PUBLIC_URL+'/actas/'+fecha_corte.nombre_archivo;
  const existe = fecha_corte.nombre_archivo.length > 0;

  return (
    <div className="col-md-12 mt-4">
      <div className="card">
        <div className="card-body">
          <div className="titulo-seccion ">
            <div className="row d-flex space-b">
              <div className="col-md-10">
                Ayuntamientos -
                <span className="txPrimario">
                  <strong>Detalle por Municipio</strong> - Votos por Candidatura
                </span>
              </div>
              <div className="col-md-2 mb-2">
                {
                  existe 
                    ? (
                      <a href={url} target="_blank" rel="noreferrer">
                        <i className="fa fa-database"></i> Base de datos
                      </a>
                    )
                    : (<><i className="fa fa-database"></i> Base de datos</>)
                }
                
              </div>
            </div>
          </div>
          <div className="f-20 mb-3">
            Municipio <span className="fw-bold">{ municipio }</span>
          </div>
          <p className="text-justify">
            El total de votos calculado y porcentaje que se muestran, se
            refieren a los votos asentados en las Actas PREP hasta el momento.
            Por presentación, los decimales de los porcentajes muestran sólo
            cuatro dígitos. No obstante, al considerar todos los decimales,
            suman 100%.
          </p>
        </div>
      </div>
    </div>
  );
};

export default VotosCandidatura;
