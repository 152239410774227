import { Link } from "react-router-dom";
import React from "react";
import background from "../assets/img/bg_inicio_back.png";
import bgCard from '../assets/img/bg_inicio.png';

const Inicio = () => {
  return (
    <div id="inicio"
      style={{
        backgroundImage: `url(${background})`
      }}
    >
      <div className="container-fluid">
        <div className="row justify-content-center p-xl-5">
          <div className="col-12 col-xl-9 inicio-card p-xl-5"
               style={{
                backgroundImage: `url(${bgCard})`,
               }}
          >
            <div className="col-xl-6 d-flex flex-row align-items-start">
              <img
                src={require('../assets/img/logo.png')} 
                alt="Logo IEPAC"
                className="img-fluid logo d-none d-xl-block"
              />
              <div className="borde-derecho ps-3 d-none d-xl-block"></div>
              <img
                src={require('../assets/img/LogoPREP.png')}
                alt="Logo PREP"
                className="img-fluid logo d-none d-xl-block ps-3"
              />
            </div>
            <div className="d-xl-none text-center pt-sm-3">
              <img
                src="{{ asset('assets/img/PantallaInicioPREP.svg') }}"
                alt="Logo PREP responsive"
                className="img-fluid logo-responsive-prep"
              />
            </div>
            <div className="col-12 col-xl-7 mb-3">
              <h3 className="f36 pt-5 fw-bold text-center text-xl-left ">
                Eleccion Extraordinaria
                <br />
                <span className="txRosaIne">Yucatán</span> 2024
              </h3>
            </div>
            <div className="col-12 col-xl-7">
              <p className="f15 pb-3 pt-3 pt-xl-1">
                Los resultados presentados son preliminares, tienen un carácter
                informativo y no son definitivos, por lo tanto carecen de
                efectos jurídicos.
              </p>
              <p className="f15 pb-3 pb-xl-1">
                El miércoles 27 de noviembre del 2024 se efectuarán los Cómputos
                Municipales, los cuales determinan los resultados finales de la
                elección de Ayuntamiento.
              </p>
            </div>
            <div className="col-12 col-xl-5 mb-2 my-xl-2">
              <Link 
                className="btn btn-inicio mt-3 mt-xl-4 w-100"
                to={process.env.PUBLIC_URL+'ayuntamientos/021-chichimila'}
              >
                Consultar Resultados Preliminares
              </Link>
              <a
                href="{{ route('ayuntamientos') }}"
                className=""
              >
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Inicio;
