import React from 'react'

const Header = () => {
  return (
    <div style={{
      position        : 'fixed',
      zIndex          : '9999',
      width           : '100%',
      display         : 'block',
      backgroundColor : '#fff'
    }}
    >
    <div className="bgMorado">&nbsp;</div>    
    <div 
      className="row d-flex justify-content-center text-center py-1" 
      style={{
        backgroundColor: '#f9f9f9'
      }}>
        <div className="col-sm-1 d-none d-sm-block">
            <img id="logoIEPAC" 
                 src={require('../assets/img/logo.png')} 
                 style={{width: '60px'}} alt="IEPAC" />
        </div>
        <div className="col-xs-12 col-md-8 order-2">
            <div className="f24">
                Programa de Resultados <span className="txRosaIne">E</span>lectorales Preliminares 2024 <br/>
                <p className="f20">Eleccion Extraordinaria <span className="txRosaIne">Yucatán</span></p>
            </div>
        </div>
        <div className="col-xs-12 col-sm-1 order-1 order-sm-1 order-md-3">
            <img 
              id="logoPREP" 
              src={require('../assets/img/LogoPREP.png')} 
              style={{
                maxHeight: '60px'
              }} alt="IEPAC" />
        </div>
    </div>
</div>
  )
}

export default Header