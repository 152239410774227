import avatar from "../assets/img/avatar-persona.svg";
import { NumericFormat } from "react-number-format";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useGetMpio } from "../hooks/useGetMpio";

const Participantes = ({ participantes, muniId }) => {
  
  muniId = useGetMpio()[0];

  const totalDet21 = participantes.totales.totales_detalle_21;
  const totalDet40 = participantes.totales.totales_detalle_40;
  
  const totales = muniId === 21 
    ? totalDet21 : totalDet40;
  
  const totalCnr = muniId === 21 
    ? participantes.totales.cnr_21 
    : participantes.totales.cnr_40;
  
  const totalPorCnr = muniId === 21 
    ? participantes.totales.porcentaje_cnr_21 
    : participantes.totales.porcentaje_cnr_40;

  const totalVnl = muniId === 21 
    ? participantes.totales.vnl_21 
    : participantes.totales.vnl_40;

  const totalPorVnl = muniId === 21 
    ? participantes.totales.porcentaje_vnl_21 
    : participantes.totales.porcentaje_vnl_40;

  const totalGen = muniId === 21 
    ? participantes.totales.total_general_21 
    : participantes.totales.total_general_40;

  const totalPorGen = muniId === 21 
    ? participantes.totales.porcentaje_votacion_21 
    : participantes.totales.porcentaje_votacion_40;

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Es la sumatoria del los votos obtenidos por Partido Político o Candidatura
      Común
    </Tooltip>
  );

  // console.log(typeof totales.orden_grafica);

  return (
    <div className="col-md-12 py-3">
      <div className="card">
        <div className="card-body">
          <h4 className="subtitulo-seccion pleca-izquierda my-3">
            <strong>Votos en Actas Contabilizadas</strong>
          </h4>
          <div className="row d-flex justify-content-center">
            {totales.orden_grafica.map((total, index) => (
              <div key={index} className="col-8 col-sm-4 col-md-3 col-lg-2">
                <div
                  className={`${
                    index === 0 && total.voto > 0 ? "cardGanador" : ""
                  } tarjeta card dis-flex flex-col mb-2`}
                >
                  <img src={avatar} alt="avatar" />
                  <p className="nombre text-center px-1">{total.candidato}</p>
                  <div className="distritos w-100 dis-flex py-4 px-1">
                    <img
                      style={{
                        height: "30px",
                        borderRadius: "0%",
                        width: "auto",
                      }}
                      src={require(`../assets/img/${total.partido}.png`)}
                      alt="logo pp"
                    />
                  </div>
                  <div className="cuerpo bgGrisTenue dis-flex w-100 p-2">
                    <div className="w-50">
                      <p>
                        <strong>Votos</strong>
                      </p>
                      <p
                        className="votos align-right textGanador"
                        style={{
                          paddingRight: "8px",
                        }}
                      >
                        <strong>
                          <NumericFormat
                            value={total.voto}
                            thousandSeparator=","
                            displayType="text"
                          />
                        </strong>
                      </p>
                    </div>
                    <div className="w-50 dis-flex flex-col align-e">
                      <p>&nbsp;</p>
                      <p>{total.porcentaje}%</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="row">
            <div className="container-fluid resumen-votacion py-3">
              <div className="row">
                <div className="bgResumen py-2 pxx col-12 col-md-12 col-lg-12 col-xl-12">
                  <p className="titulo">
                    <strong>Resumen de la votación</strong>
                  </p>
                  <div className="container-fluid">
                    <div className="row dis-flex">
                      <div className="apartado dis-flex flex-row">
                        <div className="dis-flex flex-col w-100">
                          <div className="dis-flex w-100">
                            <p className="subtitulo">Votos acumulados &nbsp;</p>
                            <OverlayTrigger
                              placement="right"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip}
                            >
                              <i
                                aria-hidden="true"
                                className="fa fa-info-circle"
                              ></i>
                            </OverlayTrigger>
                          </div>

                          <div className="row dis-flex w-100 space-b">
                            <p className="col-12 cantidad">
                              <strong>
                                <NumericFormat
                                  value={totales.total_acumulado}
                                  thousandSeparator=","
                                  displayType="text"
                                />
                              </strong>
                            </p>
                            <p className="col-12 porcentaje">
                              {totales.porcentaje_total_acumulado}%
                            </p>
                          </div>
                        </div>
                      </div>
                      <p className="separador">
                        <strong>+</strong>
                      </p>
                      <div className="apartado dis-flex flex-col pt-1">
                        <div className="dis-flex w-100">
                          <p className="subtitulo pb-1">
                            Candidaturas no registradas
                          </p>
                        </div>
                        <div className="row dis-flex w-100 space-b">
                          <p className="col-12 cantidad">
                            <strong>
                              <NumericFormat
                                value={totalCnr}
                                thousandSeparator=","
                                displayType="text"
                              />
                            </strong>
                          </p>
                          <p className="col-12 porcentaje">
                            {totalPorCnr}%
                          </p>
                        </div>
                      </div>
                      <p className="separador">
                        <strong>+</strong>
                      </p>
                      <div className="apartado dis-flex flex-col pt-1">
                        <div className="dis-flex w-100">
                          <p className="subtitulo pb-1"> Nulos </p>
                        </div>
                        <div className="row dis-flex w-100 space-b">
                          <p className="col-12 cantidad">
                            <strong>
                              <NumericFormat
                                value={totalVnl}
                                thousandSeparator=","
                                displayType="text"
                              />
                            </strong>
                          </p>
                          <p className="col-12 porcentaje">
                            {totalPorVnl}%
                          </p>
                        </div>
                      </div>
                      <p className="d-none d-lg-block separador pr-3"><strong >=</strong></p>
                      <div className="d-none d-lg-block apartado dis-flex flex-col">
                        <div className="dis-flex w-100 justify-e">
                          <p className="px20"><strong >Total de votos</strong></p>
                        </div>
                        <div className="dis-flex w-100 space-b justify-e">
                          <p className="total">
                            <strong className="txRosaIne">
                              <NumericFormat
                                value={totalGen}
                                thousandSeparator=","
                                displayType="text"
                              />
                            </strong>
                          </p>
                        </div>
                        <div className="dis-flex w-100 justify-e">
                          <p>
                            <strong>
                              <span className="txRosaIne">
                                {totalPorGen}%
                              </span>
                            </strong>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="container-fluid d-lg-none">
                      <div className="row">
                        <div className="col-12 dis-flex flex-col mt-3">
                          <div className="dis-flex w-100 justify-e">
                            <p className="px20 mb-0">
                              <strong >Total de votos</strong>
                            </p>
                          </div>
                          <div className="dis-flex w-100 justify-e">
                            <p className="total">
                              <strong>
                                =
                                <span className="txRosaIne">
                                  <NumericFormat
                                    value={totalGen}
                                    thousandSeparator=","
                                    displayType="text"
                                  />
                                </span>
                              </strong>
                            </p>
                          </div>
                          <div className="dis-flex w-100 justify-e">
                            <p>
                              <strong>
                                <span className="txRosaIne">
                                  {totalPorGen}%
                                </span>
                              </strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Participantes;
